$header-height: 75px;
.privateRoute {
  min-height: 100vh;
  background-color: aliceblue;
  .privateRoute__header {
    padding-top: $header-height;
    background-color: #3e3e3e;
    display: flex;
    align-items: center;
    .privateRoute__header--title {
      color: white;
      font-size: 30px;
      font-weight: bold;
      margin-left: 30px;
      font-style: italic;
    }
    @media only screen and (max-width: 920px) {
      .privateRoute__header--title {
        font-size: 20px;
      }
    }
  }
}
