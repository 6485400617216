@font-face {
  font-family: 'SoDoSans';
  src: local('SoDoSans'), url('./assets/fonts/SoDoSans/SoDoSansVN-SemiBold.otf') format('truetype');
}
@font-face {
  font-family: 'SoDoSansRegular';
  src: local('SoDoSansRegular'),
    url('./assets/fonts/SoDoSans/SoDoSansVN-Regular.otf') format('truetype');
}

body {
  margin: 0;
  font-family: 'SoDoSans', -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: SoDoSans, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.MuiButtonBase-root.MuiButton-root {
  text-transform: none;
  font-family: SoDoSansRegular, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.MuiFormHelperText-root.Mui-error {
  margin-left: 0;
  font-family: SoDoSansRegular, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.MuiFormLabel-root.MuiInputLabel-root {
  font-family: SoDoSansRegular, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.MuiInputBase-root {
  .MuiInputBase-input {
    font-family: SoDoSansRegular, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
}
.MuiList-root {
  .MuiButtonBase-root.MuiMenuItem-root {
    font-family: SoDoSansRegular, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace !important;
  }
}
