.globalLoading {
  position: fixed;
  backdrop-filter: invert(20%);
  width: 100%;
  height: 100%;
  z-index: 9998;

  .spin {
    position: fixed;
    top: 44%;
    left: 47%;
    z-index: 9999;
  }
}
